// Angular
import { NgModule } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// Modules
import { TranslocoRootModule } from '../transloco-root.module';
// Layout Directives
import {
  // Pipes
  FirstLetterPipe,
  FirstNamePipe,
  SearchFilterPipe,
  ArrayIncludesPipe,
  GetObjectPipe,
  JoinPipe,
  SafePipe,
  PartOriginalNoPipe,
  BackFromPrettyPipe,
  MakeMePrettyPipe,
  ModelYearPipe,
  CompanyContactNumberPipe,
  OrderByPipe,
  CountryNamePipe,
  PDPLURLPipe,
  OwnStockURLPipe,
  imageBgURLPipe,
  MonthYearDatePipe,
  EngineGearboxUrlPipe,
  VDPLURLPipe,
  VehicleIdPipe,
  CompanyRatingPipe,
  BrandLogoPipe,
  YesNoPipe,
  // Directives
  ImageLoaderDirective,
  SelectOnFocusEventDirective,
  AppShellRenderDirective,
  AppShellNoRenderDirective,
  GaSelectItemDirective,
  ShakeOnOutsideClickDirective,
  // Components
  GoogleAnalyticsComponent,
  ZendeskWidgetComponent,
  MileToKmPipe
} from './_base/layout';
// Services
import { GlobalErrorHandler, SentryService, SeoService, WebSocketService, LazyLoadScriptService, StandardUrlSerializer } from '@core/utils';
// Components
import { CookiePopupComponent } from './_base/layout';
// Shared Directives & Pipes
const DIRECTIVES_PIPES = [
  // Directives
  SelectOnFocusEventDirective,
  AppShellRenderDirective,
  AppShellNoRenderDirective,
  ImageLoaderDirective,
  GaSelectItemDirective,
  ShakeOnOutsideClickDirective,
  // Pipes
  JoinPipe,
  GetObjectPipe,
  SafePipe,
  FirstLetterPipe,
  FirstNamePipe,
  SearchFilterPipe,
  ArrayIncludesPipe,
  BackFromPrettyPipe,
  ModelYearPipe,
  CompanyContactNumberPipe,
  MakeMePrettyPipe,
  OrderByPipe,
  CountryNamePipe,
  PDPLURLPipe,
  OwnStockURLPipe,
  imageBgURLPipe,
  MonthYearDatePipe,
  PartOriginalNoPipe,
  EngineGearboxUrlPipe,
  VDPLURLPipe,
  VehicleIdPipe,
  CompanyRatingPipe,
  MileToKmPipe,
  BrandLogoPipe,
  YesNoPipe
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslocoRootModule
  ],
  declarations: [
    CookiePopupComponent,
    ZendeskWidgetComponent,
    GoogleAnalyticsComponent,
    ...DIRECTIVES_PIPES
  ],
  exports: [
    CookiePopupComponent,
    ZendeskWidgetComponent,
    GoogleAnalyticsComponent,
    ...DIRECTIVES_PIPES
  ],
  providers: [
    GlobalErrorHandler,
    SentryService,
    WebSocketService,
    SeoService,
    LazyLoadScriptService,
    ...DIRECTIVES_PIPES,
    {
      provide: UrlSerializer,
      useClass: StandardUrlSerializer
    }
  ]
})
export class CoreModule {
}
